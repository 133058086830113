<template>
  <div class="page-custom page-independent container-fluid pt-4 pb-5">
    <div class="container mt-2">
      <div class="row">
        <div class="col-xl-1"></div>
        <div class="col-xl-10 order-xl-1" v-if="loaded">
          <card shadow type="secondary">
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t.translate("TTL_USER_INFORMATION") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <section v-if="$h.hasRoles(['ADMIN', 'SUB_ADMIN', 'IT']) && profile.UserKey">
              <div class="pl-lg-4 pr-lg-4 mb-3">
                <div class="row">
                  <div class="col-12">
                    <label class="form-control-label">
                      {{ $t.translate("LBL_CURRENT_TERMS_VERSION") }}:</label
                    >
                    v.{{ currentTerms.TermsVersion }} ({{
                      $h.formatDateTime(currentTerms.TermsVersionDate)
                    }})
                  </div>

                  <div class="col-12">
                    <label class="form-control-label">
                      {{ $t.translate("LBL_ACCEPTED_TERMS_VERSION") }}:</label
                    >
                    {{ profile.TermsVersion ? `v.${profile.TermsVersion}` : "---" }}
                    <span v-if="profile.TermsVersion">
                      ({{ $h.formatDateTime(profile.TermsVersionDate) }})</span
                    >
                  </div>

                  <div class="col-12">
                    <label class="form-control-label">
                      {{ $t.translate("LBL_CURRENT_PRIVACY_VERSION") }}:</label
                    >
                    v.{{ currentTerms.PrivacyVersion }} ({{
                      $h.formatDateTime(currentTerms.PrivacyVersionDate)
                    }})
                  </div>

                  <div class="col-12">
                    <label class="form-control-label">
                      {{ $t.translate("LBL_ACCEPTED_PRIVACY_VERSION") }}:</label
                    >
                    {{ profile.PrivacyVersion ? `v.${profile.PrivacyVersion}` : "---" }}
                    <span v-if="profile.PrivacyVersion"
                      >({{ $h.formatDateTime(profile.PrivacyVersionDate) }})</span
                    >
                  </div>
                </div>
              </div>
            </section>

            <form>
              <div class="pl-lg-4 pr-lg-4">
                <div class="row">
                  <div class="col-lg-6" v-if="!IsClient">
                    <label class="form-control-label">{{
                      $t.translate("LBL_ROLE")
                    }}</label>
                    <div class="block">
                      <el-select
                        :placeholder="$t.translate('FRM_SELECT')"
                        :disabled="profile.UserKey != null || isReadonly"
                        v-model="profile.RoleCode"
                        class="form-group w-100 mb-3"
                      >
                        <el-option
                          v-for="item in RoleOptions"
                          :key="item.value"
                          :label="$t.translate(`LBL_ROLE_${item.value}`)"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="col-lg-6" v-if="!isReadonly">
                    <label class="form-control-label">{{
                      $t.translate("LBL_STATUS")
                    }}</label>
                    <div class="block form-group mb-3">
                      <el-radio v-model="profile.UserStatusCode" label="PENDING">{{
                        $t.translate("LBL_USER_PENDING")
                      }}</el-radio>
                      <el-radio v-model="profile.UserStatusCode" label="ACTIVE">{{
                        $t.translate("LBL_USER_ACTIVE")
                      }}</el-radio>
                      <el-radio v-model="profile.UserStatusCode" label="INACTIVE">{{
                        $t.translate("LBL_USER_INACTIVE")
                      }}</el-radio>
                    </div>
                  </div>

                  <div v-if="IsClient && !isReadonly" class="col-lg-6"></div>

                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_FIRSTNAME")
                    }}</label>
                    <base-input
                      :disabled="isReadonly"
                      v-model="profile.FirstName"
                      :valid="!$h.isBlank(profile.FirstName)"
                      form-classes="mb-2"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_LASTNAME")
                    }}</label>
                    <base-input
                      :disabled="isReadonly"
                      v-model="profile.LastName"
                      :valid="!$h.isBlank(profile.LastName)"
                      form-classes="mb-2"
                    />
                  </div>

                  <template v-if="profile.RoleCode != 'CLIENT'">
                    <div class="col-lg-6">
                      <label class="form-control-label">{{
                        $t.translate("FRM_DISPLAYNAME_CHS")
                      }}</label>
                      <base-input
                        :disabled="isReadonly"
                        v-model="profile.DisplayNameCHS"
                        form-classes="mb-2"
                      />
                    </div>

                    <div class="col-lg-6">
                      <label class="form-control-label">{{
                        $t.translate("FRM_DISPLAYNAME_CHT")
                      }}</label>
                      <base-input
                        :disabled="isReadonly"
                        v-model="profile.DisplayNameCHT"
                        form-classes="mb-2"
                      />
                    </div>
                  </template>

                  <!-- <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_USERNAME")
                    }}</label>
                    <base-input
                      v-model="profile.Username"
                      :valid="!$h.isBlank(profile.Username)"
                      form-classes="mb-2"
                    />
                  </div> -->
                  <div class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_EMAIL")
                    }}</label>
                    <base-input
                      :disabled="isReadonly"
                      v-model="profile.Email"
                      :valid="
                        !$h.isBlank(profile.Email) && $h.validateEmail(profile.Email)
                      "
                      type="email"
                      form-classes="mb-2"
                    />
                  </div>

                  <div v-if="IsClient && !$h.hasRoles(['BUSINESS'])" class="col-lg-6">
                    <label class="form-control-label">{{
                      $t.translate("FRM_HKQAA_BUSIN_STAFF_EMAIL")
                    }}</label>
                    <base-input
                      :disabled="isReadonly"
                      v-model="profile.BusinessStaffEmail"
                      :valid="
                        !$h.isBlank(profile.BusinessStaffEmail) &&
                        $h.validateEmail(profile.BusinessStaffEmail)
                      "
                      type="email"
                      form-classes="mb-2"
                    />
                  </div>

                  <template v-if="!IsClient">
                    <div class="col-lg-6">
                      <label class="form-control-label">{{
                        $t.translate("FRM_POSITION")
                      }}</label>
                      <base-input
                        :disabled="isReadonly"
                        v-model="profile.Position"
                        :valid="!$h.isBlank(profile.Position)"
                        form-classes="mb-2"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label class="form-control-label">{{
                        $t.translate("FRM_TEL", "Tel")
                      }}</label>
                      <el-input
                        :disabled="isReadonly"
                        v-model="profile.MobileNumber"
                        class="mb-3"
                        :class="{
                          'inp-invalid':
                            $h.isBlank(profile.MobileNumber) ||
                            !$h.isNumeric(profile.MobileNumber),
                        }"
                      >
                        <template #prepend>
                          <CountryCode v-model="profile.MobileCode" />
                        </template>
                      </el-input>
                    </div>
                  </template>

                  <div class="col-lg-6" v-if="IsStaff">
                    <label class="form-control-label">{{
                      $t.translate("FRM_GRANT_GENERATION_RIGHT")
                    }}</label>
                    <div class="mb-2">
                      <el-radio-group
                        :disabled="isReadonly"
                        v-model="profile.IsGrantGeneration"
                      >
                        <el-radio :label="1">{{ $t.translate("LBL_YES") }}</el-radio>
                        <el-radio :label="0">{{ $t.translate("LBL_NO") }}</el-radio>
                      </el-radio-group>
                    </div>
                  </div>

                  <template v-if="!profile.UserKey && !isReadonly">
                    <div class="col-lg-12" style="margin-top: 20px">
                      <div class="mt--2 mb-3 block d-flex align-items-center">
                        <el-switch
                          @change="checkValue"
                          v-model="profile.SendLink"
                          :active-value="1"
                          :inactive-value="0"
                        >
                        </el-switch>
                        <span class="form-control-label ml-2">{{
                          $t.translate("MSG_SEND_EMAIL_PASSWORD_CREATION_LINK")
                        }}</span>
                      </div>

                      <el-alert
                        v-if="!profile.SendLink"
                        style="margin-bottom: 20px"
                        :closable="false"
                        :title="$t.translate('MSG_ERR_PASSWORD_INVALID')"
                        type="warning"
                      />

                      <label class="form-control-label">{{
                        $t.translate("FRM_PASSWORD")
                      }}</label>

                      <base-input
                        v-model="profile.Password"
                        :disabled="profile.SendLink"
                        type="password"
                        :reveal="true"
                        input-classes="form-control-alternative"
                        addon-left-icon="ni ni-lock-circle-open"
                      />
                    </div>
                  </template>

                  <div v-if="profile.UserKey && !isReadonly" class="col-lg-12 mt-3">
                    <label class="form-control-label">{{
                      $t.translate("FRM_NEW_PASSWORD")
                    }}</label>
                    <base-input
                      v-model="profile.NewPassword"
                      type="password"
                      :reveal="true"
                      input-classes="form-control-alternative"
                      addon-left-icon="ni ni-lock-circle-open"
                    />

                    <label class="form-control-label">{{
                      $t.translate("FRM_CONFIRM_PASSWORD")
                    }}</label>
                    <base-input
                      v-model="profile.ConfirmPassword"
                      type="password"
                      :reveal="true"
                      input-classes="form-control-alternative"
                      addon-left-icon="ni ni-lock-circle-open"
                    />
                  </div>
                </div>
              </div>
            </form>
          </card>

          <div class="mt-4" v-if="IsClient && loaded">
            <card shadow type="secondary">
              <template #header>
                <div class="bg-white border-0">
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">
                        {{ $t.translate("LBL_ORGANIZATION_INFO") }}
                      </h3>
                    </div>
                  </div>
                </div>
              </template>

              <div class="pl-lg-4 pr-lg-4">
                <OrganizationInfo
                  :viewOnly="isReadonly"
                  :readOnly="isReadonly"
                  :user="profile"
                  :info="organizationInfo"
                  @update="(v) => updateProfData(v)"
                />
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-bottom">
    <div></div>
    <div class="d-flex">
      <el-button type="default" class="mr-4 d-none d-md-block" @click="$r.goBack">
        {{ $t.translate("BTN_CANCEL") }}
      </el-button>
      <el-button :disabled="isReadonly" type="primary" class="" @click="saveChanges">
        {{ $t.translate("BTN_CONFIRM") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import _ from "lodash";
import { onMounted, reactive, ref, computed } from "vue";
import { helpers } from "@/utils/helpers";
import { get, post, isValidResponse } from "@/utils/axios";
import { router } from "@/utils/router";
import CountryCode from "@/components/CountryCode";
import OrganizationInfo from "@/components/OrganizationInfo";

export default {
  components: { CountryCode, OrganizationInfo },
  setup() {
    const loaded = ref(false);
    const route = useRoute();
    const IsStaff = route.path.indexOf("/staff") > -1;
    const IsClient = route.path.indexOf("/user") > -1;

    let organizationInfo = ref({});
    let currentTerms = ref({});

    let isReadonly = computed(() => {
      return helpers.hasRoles(["FINANCE", "SCHEDULING"]);
    });

    let profile = reactive({
      UserKey: route?.params?.userkey || null,
      FirstName: "",
      LastName: "",
      Username: "",
      Email: "",
      BusinessStaffEmail: "",
      Position: "",
      MobileCode: "",
      MobileNumber: "",
      RoleCode: "",
      Branch: "",
      Company: "",
      UserStatusCode: "ACTIVE",
      IsGrantGeneration: 0,
      DisplayNameCHS: "",
      DisplayNameCHT: "",

      TermsVersion: "",
      TermsVersionDate: "",
      PrivacyVersion: "",
      PrivacyVersionDate: "",

      //for new user
      SendLink: 0,
      Password: "",

      //for editing user
      NewPassword: "",
      ConfirmPassword: "",
    });
    let RoleOptions = ref([]);
    let ClientList = ref([]); // the clients that the business staff handles

    const saveChanges = async () => {
      if (helpers.isSubmitting()) return;
      if (profile.UserKey && profile.NewPassword != profile.ConfirmPassword) {
        helpers.catchError({ Code: "MSG_PASSWORD_DOES_NOT_MATCH" });
        return;
      }

      let submitData = {
        ...profile,
        ...organizationInfo.value,
      };

      if (!profile?.UserKey) {
        //means create
        let {
          TermsVersion,
          TermsVersionDate,
          PrivacyVersion,
          PrivacyVersionDate,
        } = await helpers.getTCVersion();

        submitData.TermsVersion = TermsVersion;
        submitData.TermsVersionDate = TermsVersionDate;
        submitData.PrivacyVersion = PrivacyVersion;
        submitData.PrivacyVersionDate = PrivacyVersionDate;
      }

      let res = await post(profile.UserKey ? "/user/update" : "/user/create", submitData);

      if (!isValidResponse(res)) return;
      helpers.showMessage({
        Code: "MSG_PROFILE_UPDATED",
        Message: "Profile successfully updated!",
      });
      router.goBack();
    };

    onMounted(async () => {
      let { RoleList } = await get("/user/roles", {}, true);
      RoleOptions.value = _.map(RoleList, (d) => {
        return {
          value: d.RoleCode,
          label: d.RoleName,
        };
      });

      let {
        TermsVersion,
        TermsVersionDate,
        PrivacyVersion,
        PrivacyVersionDate,
      } = await helpers.getTCVersion();

      currentTerms.value = {
        TermsVersion,
        TermsVersionDate,
        PrivacyVersion,
        PrivacyVersionDate,
      };

      if (IsClient) {
        profile.RoleCode = "CLIENT";
      } else {
        profile.RoleCode = RoleOptions.value[0].value;
      }

      if (route?.params?.userkey) {
        let res = await get("/user/view", { UserKey: route.params.userkey });
        let keys = Object.keys(profile);
        for (let key of keys) {
          profile[key] = res.User[key] || "";
        }

        profile.RoleCode = res.User.UserRole;
        profile.IsGrantGeneration = res.User.IsGrantGeneration ? 1 : 0;
        organizationInfo.value = res.User.Organization;
        organizationInfo.value = {
          ...organizationInfo.value,
          ...{ GeneralEmail: res?.User?.Organization?.Email || "" },
        };
      }

      loaded.value = true;
    });

    const checkValue = () => {
      if (profile.SendLink) profile.Password = "";
    };

    const updateProfData = (data) => {
      organizationInfo.value = { ...data };
    };

    return {
      currentTerms,
      isReadonly,
      organizationInfo,
      IsStaff,
      IsClient,
      loaded,
      profile,
      RoleOptions,
      ClientList,
      saveChanges,
      checkValue,
      updateProfData,
    };
  },
};
</script>
<style></style>
